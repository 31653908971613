import { Link } from "react-router-dom";
import style from "./index.module.scss";
import Logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className={style.container}>
        <Link to="/home">
          <img src={Logo} alt="" className={style.logo} />
        </Link>

        <div className={style.row}>
          <ul>
            <h5 style={{ paddingBottom: "5%" }}>EXPLORAR</h5>
            {/* <Link to="/influencers" style={{ textDecoration: "none" }}>
              <li>Influencers</li>
            </Link> */}
            <Link to="/aboutus" style={{ textDecoration: "none" }}>
              <li>¿Quiénes Somos? </li>
            </Link>
            <Link to="/services" style={{ textDecoration: "none" }}>
              <li>¿Qué hacemos?</li>
            </Link>
          </ul>
          <ul>
            <h5 style={{ paddingBottom: "5%" }}>SERVICIOS</h5>
            <Link to="/services" style={{ textDecoration: "none" }}>
              <li>Marketing Digital</li>
              {/* <li>Influencers</li> */}
              <li>Fotografía</li>
              <li>Producción de video</li>
              <li>Diseño Web</li>
              <li>Diseño Gráfico</li>
              <li>Merchandising</li>
              <li>Contenido para redes sociales</li>
            </Link>
          </ul>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h5>SEGUINOS EN NUESTRAS REDES</h5>
            <div className={style.social}>
              <a
                href="https://www.instagram.com/brandmattagency/"
                alt="instagram brandmatt"
              >
                <i className="fa fa-instagram" style={{fontSize: "3.5vh"}}></i>
              </a>
              <a
                href="https://www.facebook.com/brandmattagency/"
                alt="facebook brandmatt"
              >
                <i className="fa fa-facebook" style={{fontSize: "3.5vh"}}></i>
              </a>
              <a
                href="https://www.tiktok.com/@brandmattagency?lang=es"
                alt="tiktok brandmatt"
              >
                <i className="fa fa-tiktok" style={{fontSize: "3.5vh"}}></i>
              </a>
              <a href="mailto:info@brandmattagency.com" alt="email">
              <i className="fa fa-envelope" style={{fontSize: "3.5vh"}}></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={style.copyright}>
        @Copyright 2021 - Created by Brandmatt Agency
      </div>
    </footer>
  );
};

export default Footer;
