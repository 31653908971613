import { Link } from "react-router-dom";
import style from "./index.module.css";
import { Fade } from "react-reveal";

const Products = () => {
  return (
    <div className={style.container} id="product">
        <h3 className={style.title}>PORQUE TU MARCA IMPORTA... </h3>

      <Fade>
        <p className={style.text}>
          Mejoramos el rendimiento de tus inversiones en publicidad, de manera
          activa y personalizada; logrando mejores retornos. concentrando los
          esfuerzos en los puntos claves.
        </p>
      </Fade>

      <div className={style.container3}>
        <Link to="/aboutus">SABER MÁS...</Link>
      </div>
    </div>
  );
};

export default Products;
