import React, { useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import Home from "./views/Home/Home";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import AboutUs from "./views/AboutUs";
import OurServices from "./views/OurServices";
import Whatsapp from "./components/Floating Button";
import AllInfluencers from "./views/AllInfluencers";
import SingleInfluencer from "./components/SingleInfluencer"

import "./App.css";

const App = () => {
  const history = useHistory();

  // lo que hace es que cada vez que hacemos un redirect con Link se haga el scroll arriba. Si no arrancaba en otro lado.
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  });

  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        {/* <Route exact path="/influencers" component={AllInfluencers} /> */}
        {/* <Route exact path="/influencers/:id" component={SingleInfluencer} /> */}
        {/* <Route exact path="/contact" component={Contact} /> */}
        <Route exact path="/aboutus" component={AboutUs} />
        <Route exact path="/services" component={OurServices} />
        <Redirect from="/" to="/" />
      </Switch>{" "}
      <Whatsapp />
      <Footer />
    </>
  );
};

export default App;
