import style from "./index.module.scss";
import { Fade } from "react-reveal";
import nosotros from "../../assets/nosotros.jpg";
import Contact from "../Contact";

const AboutUs = () => {
  return (
    <>
      <div className={style.container}>

          <h1>¿Quiénes somos?</h1>

        <div className={style.content}>
          <Fade clear duration={1200} delay={1000}>
            <img src={nosotros} alt="nosotros" />
          </Fade>
          <Fade clear cascade duration={1700} delay={1500}>
            <div className={style.text}>
              <p>
                Desde Brandmatt buscamos hacer crecer tu marca a través de la
                forma más adecuada a tu negocio teniendo en cuenta el impacto
                que se produce hoy en día el posicionamiento en las redes
                sociales a través de creadores de contenido o influencers. De la
                misma manera a través del desarrollo multimedia abarcando desde
                páginas webs hasta producción y edición de videos y fotografía.
              </p>
            </div>
          </Fade>
        </div>{" "}
        {/* cierre div content */}
        <Fade clear duration={1000} delay={1700}>
        <div className={style.about}>
          <div className={style.about2}>
           
              <h1>¿Por qué nosotros?</h1>

              <p>
                Somos conscientes de la importancia que tiene la imagen y
                presencia de una organización en Internet, por tal motivo
                conformamos un equipo de jóvenes con amplios conocimientos y
                experiencia en la implementación de soluciones efectivas de
                comunicación.
              </p>
          
          </div>
          <div className={style.about2}>
            {/* <Fade clear duration={1000} delay={1700}> */}
              <h1>¿Cuál es nuestra misión?</h1>

              <p>
                Ser la solución líder de representación de marca en internet y
                medios audio visuales, y poder mejorar los ingresos de nuestros
                clientes brindándoles el mejor servicio para su negocio.
              </p>
            {/* </Fade> */}
          </div>
          <div className={style.about2}>
            {/* <Fade duration={1000} delay={1700}> */}
              <h1>¿A qué apuntamos?</h1>

              <p>
                Creamos y mantenemos relaciones estables y duraderas en el
                tiempo, construyendo y gestionando la comunidad online de
                nuestros clientes.
              </p>
            {/* </Fade> */}
          </div>
        </div>
        </Fade>
      </div>

      <Contact />

    </>
  );
};

export default AboutUs;
