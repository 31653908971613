import "./index.css";

const Drawer = () => {
  return (
    <>
      <div id="menuArea">
        <input type="checkbox" id="menuToggle"></input>

        <label for="menuToggle" class="menuOpen">
          <div class="open"></div>
        </label>

        <div class="menu menuEffects">
          <label for="menuToggle"></label>
          <div class="menuContent">
            <ul>
              <li>
                <a href="/home">Inicio</a>
              </li>
              {/* <li>
                <a href="/influencers">Influencers</a>
              </li> */}
              <li>
                <a href="/aboutus">¿Quiénes somos?</a>
              </li>
              <li>
                <a href="/services">¿Qué hacemos?</a>
              </li>
              <div className="socialMedia">
              <a
                href="https://www.instagram.com/brandmattagency/"
                alt="instagram brandmatt"
              >
                <i className="fa fa-instagram" style={{fontSize: "3.5vh"}}></i>
              </a>
              <a
                href="https://www.facebook.com/brandmattagency/"
                alt="facebook brandmatt"
              >
                <i className="fa fa-facebook" style={{fontSize: "3.5vh"}}></i>
              </a>
              <a
                href="https://www.tiktok.com/@brandmattagency?lang=es"
                alt="tiktok brandmatt"
              >
                <i className="fa fa-tiktok" style={{fontSize: "3.5vh"}}></i>
              </a>
            </div>
            </ul>

          
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Drawer;
