import React from "react";
import { Fade, Bounce } from "react-reveal";
import style from "./index.module.scss";

import Products from "../../components/products/index";
import Arrow from "../../components/Arrow";
import Contacto from "../../views/Contact";
import Carousel3 from "../../components/Carousel3";
// import Brands from "../../components/Brands";
import FlipCards from "../../components/FlipCards";
import Slider from "../../test";
import {wppApi} from "../../utils/data" 


const Home = () => {
  return (
    <div id="home">
      <div className={style.container}>
        <Bounce cascade duration={1200} delay={1200}>
          <div className={style.sign}>
            <h2 className={style.fastFlicker}> Somos </h2>
            <h2>Brandmatt Agency </h2>
          </div>
        </Bounce>
        <Fade duration={1500} smooth delay={3000}>
          <a href={wppApi} target="_blank" rel="noreferrer">
            <button className={style.btn}> WHATSAPPEANOS</button>
          </a>
        </Fade>
      </div>
      <Arrow />
      <Products />
      {/* <Carousel3 /> */}
      <FlipCards />
      <Contacto />
    </div>
  );
};

export default Home;
