import React, { useState} from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { Fade } from "react-reveal";

import Drawer from "../Drawer/index";
import style from "./index.module.scss";
import Logo from "../../assets/logo.png";

const Navbar = () => {
  //cuando scrollea se mantiene fijo con fondo blanco
  function scrollFunction() {
    let navBar = document.getElementById("navbar");
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      navBar.style.boxShadow = "0px 0px 5px 0px black";
      navBar.style.backgroundColor = "#2d2846";
    } else {
      navBar.style.boxShadow = "";
      navBar.style.backgroundColor = "";
    }
  }
  window.onscroll = function () {
    scrollFunction();
  };

  //scroll para arriba
  const scrollToTop = () => {
    scroll.scrollToTop();
  };


  const [home, setHome] = useState(true);
  const [influencer, setInfluencer] = useState(false);
  const [about, setAbout] = useState(false);
  const [service, setService] = useState(false);

  const activeHome = () => {
    setInfluencer(false);
    setAbout(false);
    setService(false);
    setHome(true);
  };

  const activeInfluencer = () => {
    setInfluencer(true);
    setAbout(false);
    setService(false);
    setHome(false);
  };

  const activeAbout = () => {
    setInfluencer(false);
    setAbout(true);
    setService(false);
    setHome(false);
  };

  const activeService = () => {
    setInfluencer(false);
    setAbout(false);
    setService(true);
    setHome(false);
  };

  return (
    <navbar id="navbar" className={style.container}>
      <Fade >
        <div className={style.logo}>
          <Link to="/home">
            <img src={Logo} alt="" onClick={scrollToTop} />
          </Link>
        </div>
      </Fade>

        <nav className={style.items}>
          <Fade>
            <Link
              to="/home"
              className={home ? style.active : null}
              style={{ textDecoration: "none" }}
              onClick={activeHome}
            >
              Home
            </Link>
            {/* <Link
              to="/influencers"
              className={influencer ? style.active : null}
              style={{ textDecoration: "none" }}
              onClick={activeInfluencer}
            >
              Influencers
            </Link> */}
            <Link
              to="/aboutus"
              className={about ? style.active : null}
              style={{ textDecoration: "none" }}
              onClick={activeAbout}
            >
              ¿Quiénes somos?
            </Link>
            <Link
              to="/services"
              className={service ? style.active : null}
              style={{ textDecoration: "none" }}
              onClick={activeService}
            >
              ¿Qué hacemos?
            </Link>
            <a href="mailto:info@brandmattagency.com" className={style.btn}>
              CONTACTO
            </a>
          </Fade>
        </nav>

        <Drawer />
    </navbar>
  );
};

export default Navbar;
