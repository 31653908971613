import style from "./index.module.scss";
import { Fade, Slide } from "react-reveal";
import { Form, Button } from "react-bootstrap";

const address =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.667379149558!2d-58.434392684770664!3d-34.58728198046386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb5892da4869f%3A0xdd1cc9020b6d555a!2sHonduras%205225%2C%20C1414BMU%20CABA!5e0!3m2!1ses-419!2sar!4v1619009469385!5m2!1ses-419!2sar";

const Contact = () => {
  return (
    <>
      <div className={style.container}>
      <h1>TRABAJEMOS JUNTOS... 🤝 </h1>
        <div className={style.row}>
        <Slide left delay={500} distance="30px">
          <iframe
            src={address}
            title="brandmatt"
            className={style.map}
            allowfullscreen=""
            height="1000"
          ></iframe>
        </Slide>
        <Fade duration={1200} delay={1200} distance="30px">
          <div className={style.contact}>
            
            <p>Nos podes encontrar en Honduras 5225</p>
            <p>Llamanos al +54 (011) 15-7006-0080 </p>
            <h4>Dejanos tu consulta, te respondemos al toque!</h4>
            <Form
              className={style.form}
              action="https://formspree.io/f/moqyjwqj"
              method="POST"
            >
              <Form.Group>
                <Form.Control type="text" placeholder="Nombre" name="Nombre" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Control type="email" placeholder="Email" name="Email" />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control as="select" name="select">
                  <option value=""> Seleccione Categoría</option>
                  <option value="Influencer"> Influencer </option>
                  <option value="Empresa/ Agencia"> Empresa / Agencia </option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Consulta"
                  name="Mensaje"
                />
              </Form.Group>
              <Button
                type="submit"
                style={{ float: "right", backgroundColor: "rgb(194, 16, 93)" }}
              >
                Enviar!
              </Button>
            </Form>
          </div>
        </Fade>
        </div>
        
      </div>
    </>
  );
};

export default Contact;
