import style from "./index.module.scss";
import { Fade} from "react-reveal";
import Contact from "../Contact"

const apiwpp =
  "https://wa.link/kxibyq";

const OurServices = () => {
  return (
    <>
    <div className={style.container}>
    {/* <Bounce cascade > */}
        <h1>¿Qué hacemos?</h1>
        {/* </Bounce> */}
      <div id="marketing" className={style.content}>
        <Fade left duration={1200} delay={1000}>
          <img src="https://i.imgur.com/88EN56D.png" alt="" className={style.image} />
        </Fade>
        <Fade right duration={1200} delay={1000} >
          <div className={style.text}>
            <h2>MARKETING DIGITAL</h2>
            <p>
              Mediante campañas publicitarias online y de de e-mail marketing lo
              ayudamos a llegar a sus potenciales clientes.
            </p>
            <a href={apiwpp}> ME INTERESA </a>
          </div>
        </Fade>
      </div>

      <div id="influencers" className={style.content2}>
        <Fade left duration={1200} delay={1000} >
          <img src="https://i.imgur.com/IDlBzs6.jpg" alt="" className={style.image} />
        </Fade>
        <Fade right duration={1200} delay={1000} >
          <div className={style.text}>
            <h2> INFLUENCERS</h2>
            <p>
              Ayudamos a llevar su marca a potenciales clientes de forma masiva
              a través de las redes sociales. Representamos al equipo de
              influencers más grande del país.
            </p>
            <a href={apiwpp}> ME INTERESA </a>
          </div>
        </Fade>
      </div>

      <div id="fotografia" className={style.content}>
        <Fade left duration={1000} delay={1000} >
          <img src="https://i.imgur.com/0283Wip.jpg" alt="" className={style.image} />
        </Fade>
        <Fade right duration={1000} delay={1000} >
          <div className={style.text}>
            <h2>FOTOGRAFÍA</h2>
            <p>
              Contamos con la experiencia y la estructura para poder producir
              cualquier tipo de fotografía publicitaria, desde pequeños
              foto-productos hasta producciones integrales en estudio y en
              locaciones.
            </p>
            <a href={apiwpp}> ME INTERESA </a>
          </div>
        </Fade>
      </div>

      <div id="video" className={style.content2}>
        <Fade left duration={1200} delay={1000} >
          <img src="https://i.imgur.com/sybJETv.jpg" alt="" className={style.image} />
        </Fade>
        <Fade right duration={1200} delay={1000}>
          <div className={style.text}>
            <h2>EDICIÓN DE VIDEOS</h2>
            <p>
              Apostamos por el estudio al más alto nivel. Contamos con la última
              tecnología en el ámbito audiovisual, resultando en trabajos de
              calidad cinematográfica con la creatividad que nos caracteriza.
            </p>
            <a href={apiwpp}> ME INTERESA </a>
          </div>
        </Fade>
      </div>

      <div id="diseño" className={style.content}>
        <Fade left duration={1000} delay={1000}>
          <img src="https://i.imgur.com/bGrfIUv.jpg" alt="" className={style.image} />
        </Fade>
        <Fade right duration={1000} delay={1000} >
          <div className={style.text}>
            <h2>DISEÑO GRÁFICO</h2>
            <p>
              Transformamos sus mensajes en comunicación visual de una forma
              creativa, adaptada a grupos sociales específicos y con objetivos
              determinados.
            </p>
            <a href={apiwpp}> ME INTERESA </a>
          </div>
        </Fade>
      </div>

      <div id="web" className={style.content2}>
        <Fade left duration={1200} delay={1000} >
          <img src="https://i.imgur.com/NmT8mdC.png" alt="" className={style.image} />
        </Fade>
        <Fade right duration={1200} delay={1000} >
          <div className={style.text}>
            <h2>DISEÑO WEB / E-COMMERCE</h2>
            <p>
              Planificamos,y desarrollamos sitios web con diseños creativos e
              innovadores adaptados a sus necesidades.
            </p>
            <a href={apiwpp}> ME INTERESA </a>
          </div>
        </Fade>
      </div>

      <div id="social" className={style.content}>
        <Fade left duration={1200} delay={1000} >
          <img src="https://i.imgur.com/YS16pOe.jpg" alt="" className={style.image} />
        </Fade>
        <Fade right duration={1200} delay={1000} >
          <div className={style.text}>
            <h2>CONTENIDO PARA REDES SOCIALES</h2>
            <p>
              Construímos, gestionamos y administramos la comunidad online
              alrededor de su marca en Internet.
            </p>
            <a href={apiwpp}> ME INTERESA </a>
          </div>
        </Fade>
      </div>

      <div id="social" className={style.content2}>
        <Fade left duration={1200} delay={1000} >
          <img src="https://i.imgur.com/2q8L9yA.jpg" alt="" className={style.image} />
        </Fade>
        <Fade right duration={1200} delay={1000} >
          <div className={style.text}>
            <h2>MERCHANDISING</h2>
            <p>
              Hacé crecer tu marca y ganá más reconocimiento con productos que puedan dar a conocerte.
            </p>
            <a href={apiwpp}> ME INTERESA </a>
          </div>
        </Fade>
      </div>

    </div>
    <Contact/>
    </>
  );
};

export default OurServices;
