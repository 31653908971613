import "./index.css";
import { Link } from "react-scroll";
import { Fade } from "react-reveal";

const Arrow = () => {
  return (
    <div className="arr">
      <Fade duration={1000} delay={800} distance="30px">
        <Link to="product" smooth duration={1000} offset={-200}>
          <div className="arrow">
            <span></span>
            <span></span>
            {/* <span></span> */}
          </div>
        </Link>
      </Fade>
    </div>
  );
};

export default Arrow;
