import style from "./index.module.scss";
import React from "react";
import { Link } from "react-router-dom";

const FlipCards = () => {
  return (
    <div className={style.box}>
      <h1>¿QUÉ HACEMOS? </h1>
      <div className={style.container}>
        <div className={style.flipBox}>
          <div className={style.front}>
            <div className={style.content}>
              <img
                src="https://i.imgur.com/eoMCbJF.jpg"
                alt=""
                height="100%"
                width="100%"
              />
              <h2>MARKETING DIGITAL</h2>
            </div>
          </div>
          <div className={style.back}>
            <Link to={`/services`}>
              <div className={style.content}>
                <img
                  src="https://i.imgur.com/eoMCbJF.jpg"
                  alt=""
                  height="100%"
                  width="100%"
                />

                <h3> SABER MAS </h3>
              </div>
            </Link>
          </div>
        </div>

        <div className={style.flipBox}>
          <div className={style.front}>
            <div className={style.content}>
              <img
                src="https://i.imgur.com/7l0CVNN.jpg"
                alt=""
                height="100%"
                width="100%"
              />
              <h2>Influencers</h2>
            </div>
          </div>
          <div className={style.back}>
            <Link to={`/services`}>
              <div className={style.content}>
                <img
                  src="https://i.imgur.com/7l0CVNN.jpg"
                  alt=""
                  height="100%"
                  width="100%"
                />
                <h3> SABER MAS </h3>
              </div>
            </Link>
          </div>
        </div>

        <div className={style.flipBox}>
          <div className={style.front}>
            <div className={style.content}>
              <img
                src="https://i.imgur.com/yH7LoUy.jpg"
                alt=""
                height="100%"
                width="100%"
              />
              <h2>Fotografía</h2>
            </div>
          </div>
          <div className={style.back}>
            <Link to={`/services`}>
              <div className={style.content}>
                <img
                  src="https://i.imgur.com/yH7LoUy.jpg"
                  alt=""
                  height="100%"
                  width="100%"
                />
                <h3> SABER MAS </h3>
              </div>
            </Link>
          </div>
        </div>

        <div className={style.flipBox}>
          <div className={style.front}>
            <div className={style.content}>
              <img
                src="https://i.imgur.com/HK5m2QK.jpg"
                alt=""
                height="100%"
                width="100%"
              />
              <h2>Producción de Video</h2>
            </div>
          </div>
          <div className={style.back}>
            <Link to={`/services`}>
              <div className={style.content}>
                <img
                  src="https://i.imgur.com/HK5m2QK.jpg"
                  alt=""
                  height="100%"
                  width="100%"
                />
                <h3> SABER MAS </h3>
              </div>
            </Link>
          </div>
        </div>

        <div className={style.flipBox}>
          <div className={style.front}>
            <div className={style.content}>
              <img
                src="https://i.imgur.com/XdCokY2.jpg"
                alt=""
                height="100%"
                width="100%"
              />
              <h2>Diseño Gráfico</h2>
            </div>
          </div>
          <div className={style.back}>
            <Link to={`/services`}>
              <div className={style.content}>
                <img
                  src="https://i.imgur.com/XdCokY2.jpg"
                  alt=""
                  height="100%"
                  width="100%"
                />
                <h3> SABER MAS </h3>
              </div>
            </Link>
          </div>
        </div>

        <div className={style.flipBox}>
          <div className={style.front}>
            <div className={style.content}>
              <img
                src="https://i.imgur.com/1CYNMeG.jpg"
                alt=""
                height="100%"
                width="100%"
              />
              <h2>Diseño Web </h2>
            </div>
          </div>
          <div className={style.back}>
            <Link to={`/services`}>
              <div className={style.content}>
                <img
                  src="https://i.imgur.com/1CYNMeG.jpg"
                  alt=""
                  height="100%"
                  width="100%"
                />
                <h3> SABER MAS </h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlipCards;
