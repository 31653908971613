import WhatsappIcon from "../../assets/WhatsappIcon.png";
import {wppApi }from "../../utils/data"
const apiwpp =
  "https://wa.link/kxibyq";

const Whatsapp = () => {
  return (
    <a href={wppApi} target="_blank" rel="noreferrer" c>
      <img
        src={WhatsappIcon}
        width="50px"
        style={{
          right: "10px",
          position: "fixed",
          bottom: "20px",
          zIndex: 1,
          filter: "drop-shadow(0px 0px 5px green)",
        }}
        alt="wpp"
      />
    </a>
  );
};

export default Whatsapp;
